import { Stack, Typography } from '@mui/material';
import type { ReactNode } from 'react';

import { SearchNotPossibleIcon } from './SearchNotPossibleIcon';

export const SearchNotPossible = ({ children }: { children: ReactNode }) => {
  return (
    <Stack alignItems="center" gap={2} padding={6}>
      <SearchNotPossibleIcon />
      <Typography variant="body1">{children}</Typography>
    </Stack>
  );
};
