import { ToggleButton, ToggleButtonGroup as MuiToggleButtonGroup } from '@mui/material';
import type { Dispatch, ReactNode, SetStateAction } from 'react';

export const ToggleButtonGroup = <T extends string | number>({
  values,
  value,
  setValue,
  disabled,
}: {
  values: { value: T; label: ReactNode }[];
  value: T;
  setValue: Dispatch<SetStateAction<T>>;
  disabled?: boolean;
}) => {
  return (
    <MuiToggleButtonGroup
      disabled={disabled}
      exclusive
      fullWidth
      onChange={(_, value) => setValue(value)}
      value={value}
    >
      {values.map((value) => (
        <ToggleButton color="primary" key={value.value} sx={{ flex: 1 }} value={value.value}>
          {value.label}
        </ToggleButton>
      ))}
    </MuiToggleButtonGroup>
  );
};
