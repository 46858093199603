import { useContext } from 'react';

import {
  FiltersContext,
  type FilterValues,
  type FilterStates,
  type TrassFilter,
  TrassFilterEnum,
} from '@components/FiltersProvider';

export type { FilterValues, FilterStates, TrassFilter };
export { TrassFilterEnum };

export const useFilters = (): FilterValues => {
  return useContext(FiltersContext);
};
