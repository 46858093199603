import type { ReactNode } from 'react';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';

import { ErrorPage } from '@components/ErrorPage';
import { useFeatureFlags } from '@hooks/useFeatureFlags';
import { Layout as LayoutBilling } from '@pages/billing/layout';
import { Page as PageBilling } from '@pages/billing/page';
import { Error as ErrorRoot } from '@pages/error';
import { Layout as LayoutRoot } from '@pages/layout';
import { Page as PageMapFeature } from '@pages/map/feature/[featureId]/page';
import { Page as PageMap } from '@pages/map/page';
import { Page as PageMapPhoto } from '@pages/map/photo/[photoId]/page';
import { Page as PageMapTrajectory } from '@pages/map/scan/[scanId]/[elementType]/page';
import { Page as PageMapSegment } from '@pages/map/segment/[segmentId]/page';
import { Page as PageWelcome } from '@pages/map/welcome/page';
import { Page as PageListPhoto } from '@pages/photo/page';
import { Page as PageProject } from '@pages/project/page';
import { Page as PageConstructionProgress } from '@pages/statistics/construction-progress/page';
import { Page as PageLabelingProgress } from '@pages/statistics/labeling-progress/page';
import { Layout as LayoutStatistics } from '@pages/statistics/layout';

const ProtectedRoute = ({
  isAllowed,
  children,
  notAllowedAction = 'error',
}: {
  isAllowed: boolean;
  children?: ReactNode;
  notAllowedAction?: 'redirect' | 'error';
}) => {
  if (!isAllowed) {
    return notAllowedAction === 'redirect' ? (
      <Navigate replace to="/map" />
    ) : (
      <ErrorPage errorText="Not allowed" statusCode={405} />
    );
  }

  return children ? children : <Outlet />;
};

export const AppRoutes = () => {
  const { isBillingEnabled, isCockpitMigrationEnabled, isStatisticsEnabled } = useFeatureFlags();

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<LayoutRoot />} path="/">
          <Route
            element={
              <Navigate
                to={{
                  pathname: 'map',
                }}
              />
            }
            index
          />
          <Route element={<PageMap />} path="map">
            <Route element={<ProtectedRoute isAllowed={isCockpitMigrationEnabled} />}>
              <Route element={<PageWelcome />} path="welcome" />
            </Route>
            <Route element={<PageMapTrajectory />} path="scan/:scanId/:elementType/:tab?" />
            <Route element={<PageMapPhoto />} path="photo/:photoId/:photoIndex" />
            <Route element={<PageMapSegment />} path="segment/:segmentId" />
            <Route element={<PageMapFeature />} path="feature/:featureId" />
          </Route>
          <Route element={<ProtectedRoute isAllowed={isBillingEnabled} />}>
            <Route element={<LayoutBilling />}>
              <Route element={<PageBilling />} path="billing" />
            </Route>
          </Route>
          <Route element={<ProtectedRoute isAllowed={isCockpitMigrationEnabled} />}>
            <Route element={<PageProject />} path="project" />
            <Route element={<PageListPhoto />} path="photo" />
          </Route>
          <Route element={<ProtectedRoute isAllowed={isStatisticsEnabled} />}>
            <Route element={<LayoutStatistics />} path="statistics">
              <Route element={<Navigate to="construction-progress" />} index />
              <Route element={<PageConstructionProgress />} path="construction-progress" />
              <Route element={<PageLabelingProgress />} path="labeling-progress" />
            </Route>
          </Route>
          <Route element={<ErrorRoot />} path="*" />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
