import { commonApiV1 as commonApi } from '@deepup/apis';
import { CircleInformationOutline } from '@deepup/icons';
import {
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  useTheme,
  Typography,
  debounce,
} from '@mui/material';
import { type BarChartProps, BarChart } from '@mui/x-charts';
import type { DateTimeFormatOptions } from 'luxon';
import { useEffect, useMemo, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useApiTimeSeries } from '@hooks/useApiConstructionProgress';
import { useFilters } from '@hooks/useFilters';
import { mapSum } from '@utils/core';
import { fromTimestamp } from '@utils/timeFormatting';

import { ProgressTableClient } from './ProgressTable';

const useChartSeries = () => {
  const { t } = useTranslation();

  const trenches: BarChartProps['series'] = [
    {
      dataKey: 'prelabeledMeters',
      stack: 'trench m',
      label: t('pages.statistics.constructionProgress.progressChart.aiMeters'),
      valueFormatter: (val = 0) => t('decimal', { val }),
    },
    {
      dataKey: 'labeledMeters',
      stack: 'trench m',
      label: t('pages.statistics.constructionProgress.progressChart.qualityMeters'),
      valueFormatter: (val = 0) => t('decimal', { val }),
    },
  ];
  const houseLeads: BarChartProps['series'] = [
    {
      dataKey: 'numberOfHouseLeads',
      stack: 'house leads',
      label: t('pages.statistics.constructionProgress.progressChart.nrHouseLeads'),
    },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => ({ trenches, houseLeads }), [t]);
};

const Chart = ({
  dataset,
  colors,
  loading,
  onSelectSeries,
  series,
}: {
  onSelectSeries: (index: number) => void;
} & Pick<BarChartProps, 'series' | 'dataset' | 'colors' | 'loading'>) => (
  <BarChart
    colors={colors}
    dataset={dataset}
    grid={{ horizontal: true }}
    loading={loading}
    onItemClick={(_, item) => {
      onSelectSeries(item.dataIndex);
    }}
    series={series}
    xAxis={[
      {
        scaleType: 'band',
        dataKey: 'date',
      },
    ]}
  />
);

const dateFormats: Record<number, DateTimeFormatOptions> = {
  [commonApi.Interval.Interval_DAILY]: { month: '2-digit', day: '2-digit' },
  [commonApi.Interval.Interval_WEEKLY]: { month: '2-digit', day: '2-digit' },
  [commonApi.Interval.Interval_MONTHLY]: { month: '2-digit' },
};

const intervalTranslations: [commonApi.Interval, number, string][] = [
  [commonApi.Interval.Interval_DAILY, 0, 'pages.statistics.DAILY'],
  [commonApi.Interval.Interval_WEEKLY, 1, 'pages.statistics.WEEKLY'],
  [commonApi.Interval.Interval_MONTHLY, 2, 'pages.statistics.MONTHLY'],
];

export const Charts = () => {
  const theme = useTheme();
  const { interval, setInterval, scanDevices, projectList, dateRange } = useFilters();

  const { items, isFetching, hasNextPage, fetchNextPage } = useApiTimeSeries(
    projectList,
    scanDevices,
    dateRange,
    interval,
  );

  useEffect(() => {
    if (hasNextPage && !isFetching) fetchNextPage();
  }, [hasNextPage, isFetching, fetchNextPage, items]);

  const [selectedSeries, setSelectedSeries] = useState<number>();

  useEffect(() => setSelectedSeries(undefined), [interval]); // hide table when interval changes

  const { t } = useTranslation();

  const dataset = items.map(({ timeRange, progress, totalMeters }) => ({
    date: fromTimestamp(timeRange!.start!).toLocaleString(dateFormats[interval]),
    totalMeters,
    numberOfHouseLeads: mapSum(progress, (p) => p.numberOfHouseLeads),
    prelabeledMeters: mapSum(progress, (p) => p.prelabeledMeters),
    labeledMeters: mapSum(progress, (p) => p.labeledMeters),
  }));

  const series = useChartSeries();

  const isStillFetchingPages = isFetching || hasNextPage;

  const ref = useRef<HTMLDivElement>(null);

  const scrollToGrid = debounce(() => {
    ref.current?.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    });
  }, 50);

  return (
    <>
      <Stack alignItems="center">
        <ToggleButtonGroup size="small" value={interval}>
          {intervalTranslations.map(([name, number, translationKey]) => (
            <ToggleButton
              key={name}
              onClick={(_, nextInterval) => setInterval(nextInterval)}
              value={number}
            >
              {t(translationKey as never)}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Stack>
      <Typography alignItems="center" color="secondary" display="flex" gap={1} variant="caption">
        <CircleInformationOutline />
        {t('pages.statistics.constructionProgress.progressChart.info')}
      </Typography>
      <Stack direction="row" height={350} ref={ref}>
        <Chart
          colors={[theme.palette.primary.dark, theme.palette.primary.main]}
          dataset={dataset}
          loading={isStillFetchingPages}
          onSelectSeries={setSelectedSeries}
          series={series.trenches}
        />
        <Chart
          colors={[theme.palette.warning.main]}
          dataset={dataset}
          loading={isStillFetchingPages}
          onSelectSeries={setSelectedSeries}
          series={series.houseLeads}
        />
      </Stack>
      {selectedSeries !== undefined && items[selectedSeries] && (
        <ProgressTableClient
          onStateChange={scrollToGrid}
          progress={items[selectedSeries].progress}
        />
      )}
    </>
  );
};
