import { photoApiV1 as photoApi, commentApiV1 as commentApi } from '@deepup/apis';
import { LoadingScreen } from '@deepup/loading-screen';
import { Alert, Box, type Theme } from '@mui/material';
import { t } from 'i18next';
import { type SetStateAction, type Dispatch } from 'react';

import { Image } from '@components/Image';
import { ImageDownload } from '@components/ImageDownload';
import { ImageZoom } from '@components/ImageZoom';
import { PhotoInfoCard } from '@components/PhotoInfoCard';
import { usePhotoItem } from '@hooks/useApiPhoto';
import { localizeTimestampApi } from '@utils/timeFormatting';

import { getPhotoFilename } from './utils';

type Props = {
  photoId: string;
  isFullscreen: boolean;
  expanded: boolean;
  setExpanded: Dispatch<SetStateAction<boolean>>;
};

const mapPhotoInfoCard = (photo: photoApi.Photo) => ({
  id: photo.id,
  address: `${photo.location?.place} ${photo.location?.city}`,
  category: photo.categoryName,
  date: localizeTimestampApi(photo.recordedAt) as string,
  device: photo.deviceId,
  solution: geolocationDescriptions[photo.geoPositionSolution],
  comments: photo.comments.filter((item) => item.type === commentApi.CommentType.NOTE),
  commentsDevice: photo.comments.filter((item) => item.type === commentApi.CommentType.DEVICE),
});

const geolocationDescriptions: Record<photoApi.GeolocationSolution, string> = {
  [photoApi.GeolocationSolution.NONE]: t('hooks.useFetchPhotoInfo.NONE'),
  [photoApi.GeolocationSolution.CODE]: t('hooks.useFetchPhotoInfo.CODE'),
  [photoApi.GeolocationSolution.CORELOCATION]: t('hooks.useFetchPhotoInfo.CORELOCATION'),
  [photoApi.GeolocationSolution.DGNSS]: t('hooks.useFetchPhotoInfo.DGNSS'),
  [photoApi.GeolocationSolution.FLOAT]: t('hooks.useFetchPhotoInfo.FLOAT'),
  [photoApi.GeolocationSolution.FIXED]: t('hooks.useFetchPhotoInfo.FIXED'),
};

// TODO - Adjust the mocked PhotoInfo compoent
// 1. re-use existing PhotoInfo on the map page and usePhotoInfo hook
// 2. replace conmon api call for photo info with the unified api call
export const PhotoInfo = ({ photoId, isFullscreen, expanded, setExpanded }: Props) => {
  const { data: photoData, isLoading, isError } = usePhotoItem(photoId);

  if (isLoading || photoData === undefined) {
    return <LoadingScreen />;
  }
  if (isError) {
    return <Alert severity="error">{t('components.photoDownload.errors.photo')}</Alert>;
  }

  const photoInfo = mapPhotoInfoCard(photoData);
  const imageName = getPhotoFilename(photoData);

  return (
    <Box
      component="div"
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: isFullscreen ? 'row' : 'column',
        padding: isFullscreen ? 0 : '16px',
        overflow: 'auto',
      }}
    >
      <Box
        component="div"
        sx={(theme: Theme) => ({
          flex: 1,
          height: '100%',
          width: '100%',
          minHeight: '200px',
          background: theme.palette.grey[50],
          borderRadius: isFullscreen ? '0' : '10px',
          overflow: 'hidden',
          position: 'relative',
        })}
      >
        <ImageDownload
          id={photoId}
          name={imageName}
          options={{ stripMetadata: false }}
          sx={{ position: 'absolute', top: '10px', right: '60px', zIndex: 1 }}
        />
        <ImageZoom
          image={
            <Image
              alt={`${t('pages.map.photo.imageAltCategory')}:${photoInfo?.category}`}
              height={2000}
              id={photoId}
              sx={{
                width: '100%',
                height: '100%',
                display: 'block',
              }}
              sxLoading={{
                width: '100%',
                height: '700px',
              }}
            />
          }
          padding={0}
        />
      </Box>
      <Box
        component="div"
        sx={{
          width: isFullscreen ? 'min(30%, 400px)' : 'auto',
          overflowY: 'auto',
        }}
      >
        <PhotoInfoCard
          collapsable={!isFullscreen}
          expanded={expanded}
          info={photoInfo}
          setExpanded={setExpanded}
        />
      </Box>
    </Box>
  );
};
