import {
  timestampApi,
  labelingProgressApiV1 as labelingProgressApi,
  commonApiV1 as commonApi,
} from '@deepup/apis';
import { useDeepUpTheme } from '@deepup/mui-theme-deepup';
import { debounce, Stack, Typography } from '@mui/material';
import type { BarItemIdentifier } from '@mui/x-charts';
import { BarChart } from '@mui/x-charts/BarChart';
import { DateTime } from 'luxon';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ProgressTable } from './ProgressTable';

const timeRangeToIntervalString = (
  timeRange: commonApi.TimeRange | undefined,
  interval: commonApi.Interval,
  locale: string,
) => {
  const dateFrom = timeRange?.start ? timestampApi.Timestamp.toDate(timeRange.start) : null;
  const dateTimeFrom = dateFrom ? DateTime.fromJSDate(dateFrom, { zone: 'utc' }) : null;

  const dateTo = timeRange?.end ? timestampApi.Timestamp.toDate(timeRange.end) : null;
  const dateTimeTo = dateTo
    ? DateTime.fromJSDate(dateTo, { zone: 'utc' }).minus({ days: 1 })
    : null;

  if (!dateTimeFrom || !dateTimeFrom.isValid || !dateTimeTo || !dateTimeTo.isValid) {
    return '';
  }

  if (interval === commonApi.Interval.Interval_DAILY) {
    return dateTimeFrom.setLocale(locale).toFormat('D');
  }

  return `${dateTimeFrom.setLocale(locale).toFormat('D')} - ${dateTimeTo.setLocale(locale).toFormat('D')}`;
};

export const TimeSeriesChart = ({
  items,
  interval,
  isLoading,
}: {
  items?: labelingProgressApi.GetTimeSeriesResponse['items'];
  interval: commonApi.Interval;
  isLoading: boolean;
}) => {
  const { i18n } = useTranslation();
  const [progress, setProgress] = useState<
    labelingProgressApi.GetTotalProgressResponse['items'] | undefined
  >(undefined);
  const [selectedInterval, setSelectedInterval] = useState<string | null>(null);
  const { t } = useTranslation();
  const { theme } = useDeepUpTheme();
  const containerRef = useRef<HTMLDivElement | null>(null);

  // remove progress table on data change
  useEffect(() => {
    setProgress(undefined);
    setSelectedInterval(null);
  }, [items, interval]);

  const dataSeries =
    items?.[0]?.intervals.map((_, index) => {
      return items.reduce<number>((acc, curr) => {
        return acc + (curr.intervals[index]?.progress?.totalMeters ?? 0);
      }, 0);
    }) ?? [];

  const dataXAxis =
    items?.[0]?.intervals.map((item) => {
      return timeRangeToIntervalString(item.timeRange, interval, i18n.language);
    }) ?? [];

  const onItemClick = (_: unknown, barItemIdentifier: BarItemIdentifier) => {
    if (isLoading) {
      return;
    }

    const progress = items?.map((item) => ({
      project: item.project,
      progress: item.intervals[barItemIdentifier.dataIndex]?.progress,
    }));

    setProgress(progress);
    setSelectedInterval(
      timeRangeToIntervalString(
        items?.[0]?.intervals[barItemIdentifier.dataIndex]?.timeRange,
        interval,
        i18n.language,
      ),
    );
  };

  const selectedIntervalText = {
    [commonApi.Interval.Interval_DAILY]: t('pages.statistics.labelingProgress.selectedDay'),
    [commonApi.Interval.Interval_WEEKLY]: t('pages.statistics.labelingProgress.selectedWeek'),
    [commonApi.Interval.Interval_MONTHLY]: t('pages.statistics.labelingProgress.selectedMonth'),
  };

  return (
    <Stack ref={containerRef}>
      <Typography variant="subtitle2">
        {t('pages.statistics.labelingProgress.barChart.infoText')}
      </Typography>
      <BarChart
        borderRadius={theme.shape.borderRadius}
        grid={{ horizontal: true }}
        height={280}
        loading={isLoading}
        // https://mui.com/x/react-charts/axis/#fixing-overflow-issues
        margin={{ left: 75 }}
        onItemClick={onItemClick}
        series={[
          {
            data: dataSeries,
            color: '#008066',
            label: t('pages.statistics.labelingProgress.barChart.total'),
            valueFormatter: (val) => t('decimal', { val }),
          },
        ]}
        slotProps={{
          loadingOverlay: {
            message: t('common.loadingData'),
          },
          legend: { hidden: true },
        }}
        xAxis={[
          {
            id: 'barDates',
            data: dataXAxis,
            scaleType: 'band',
          },
        ]}
      />
      {progress && selectedInterval && (
        <>
          <Typography variant="subtitle2">
            {selectedIntervalText[interval]} {selectedInterval}
          </Typography>
          <ProgressTable
            onStateChange={debounce(() =>
              containerRef.current?.scrollIntoView({ behavior: 'smooth' }),
            )}
            paginationMode="client"
            progress={progress}
          />
        </>
      )}
    </Stack>
  );
};
