import Box from '@mui/material/Box';
import MuiTab from '@mui/material/Tab';
import MuiTabs from '@mui/material/Tabs';
import { useState, type SyntheticEvent, type ReactNode, useEffect } from 'react';

type TabPanelProps = {
  children?: ReactNode;
  index: number;
  value: number;
  hasVisited: boolean;
  isFullHeight?: boolean;
};

export type TabsProps = {
  items: { label: ReactNode; children: ReactNode }[];
  index?: number;
  ariaLabel?: string;
  onChange?: (index: number) => void;
  isFullHeight?: boolean;
  variant?: 'scrollable' | 'standard';
};

const TabPanel = ({ children, value, index, hasVisited, isFullHeight = true }: TabPanelProps) => {
  return (
    <div
      aria-labelledby={`tab-${index}`}
      hidden={value !== index}
      id={`tabpanel-${index}`}
      role="tabpanel"
      style={isFullHeight ? { height: '100%' } : undefined}
    >
      {hasVisited && (
        <Box
          component="div"
          sx={{
            height: isFullHeight ? '100%' : undefined,
            minHeight: isFullHeight ? '300px' : undefined,
            boxSizing: 'border-box',
            display: value === index ? 'block' : 'none',
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
};

export const Tabs = ({
  items,
  ariaLabel,
  onChange,
  index = 0,
  isFullHeight = true,
  variant = 'standard',
}: TabsProps) => {
  const [value, setValue] = useState(index);
  const [visited, setVisited] = useState(new Set([index]));

  const addVisited = (value: number) => {
    setVisited((prev) => new Set(prev.add(value)));
  };

  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    onChange?.(newValue);
    setValue(newValue);
    addVisited(newValue);
  };

  useEffect(() => {
    setValue(index);
    addVisited(index);
  }, [index]);

  return (
    <Box
      component="div"
      sx={{
        width: '100%',
        height: isFullHeight ? '100%' : undefined,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
      }}
    >
      <Box component="div" sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <MuiTabs
          aria-label={ariaLabel}
          indicatorColor="primary"
          onChange={handleChange}
          scrollButtons="auto"
          textColor="primary"
          value={value}
          variant={variant}
        >
          {items.map(({ label }, index) => (
            <MuiTab
              key={index}
              label={label}
              sx={{ textTransform: 'none' }}
              {...a11yProps(index)}
            />
          ))}
        </MuiTabs>
      </Box>
      <Box component="div" sx={{ flex: 1, overflow: 'auto' }}>
        {items.map(({ children }, index) => (
          <TabPanel
            hasVisited={visited.has(index)}
            index={index}
            isFullHeight={isFullHeight}
            key={index}
            value={value}
          >
            {children}
          </TabPanel>
        ))}
      </Box>
    </Box>
  );
};
