import type { Feature } from 'geojson';
import type { TFunction } from 'i18next';
import { DateTime } from 'luxon';

import { toNumber } from '@utils/core';

const usageTypeArray = ['TRASS', 'HOUSE_LEAD'] as const;
const layingTypeArray = [
  'SOIL_DISPLACEMENT',
  'PULL',
  'OPEN_CONSTRUCTION',
  'HORIZONTAL_DIRECTIONAL_DRILLING',
  'PLOUGH',
  'MICRO_TRENCHING',
  'LAYJET',
] as const;
const surfaceTypesArray = [
  'ASPHALT',
  'CONCRETE',
  'UNPAVED_GRASS',
  'SIMPLE_BRICK',
  'HIGH_QUALITY_BRICK',
  'MISSING',
] as const;

export type UsageType = (typeof usageTypeArray)[number];
export type LayingType = (typeof layingTypeArray)[number];
export type SurfaceType = (typeof surfaceTypesArray)[number];

export const usageTypeColors: Record<UsageType, string> = {
  HOUSE_LEAD: '#0000ff',
  TRASS: '#990033',
};

export const surfaceTypeColors: Record<SurfaceType, string> = {
  UNPAVED_GRASS: '#7CFC00',
  SIMPLE_BRICK: '#BF40BF',
  HIGH_QUALITY_BRICK: '#570861',
  ASPHALT: '#006ee6',
  MISSING: '#f28c8a',
  CONCRETE: '#FFA500',
};

export const layingTypeColors: Record<LayingType, string> = {
  SOIL_DISPLACEMENT: '#d5b60a',
  PULL: '#FF50F5',
  OPEN_CONSTRUCTION: '#990033',
  HORIZONTAL_DIRECTIONAL_DRILLING: '#7CFC00',
  PLOUGH: '#4597C9',
  LAYJET: '#FEE9FE',
  MICRO_TRENCHING: '#DC2629',
};

const isUsageType = (input: string | undefined): input is UsageType =>
  usageTypeArray.includes(input as UsageType);
const isLayingType = (input: string | undefined): input is LayingType =>
  layingTypeArray.includes(input as LayingType);
const isSurfaceType = (input: string | undefined): input is SurfaceType =>
  surfaceTypesArray.includes(input as SurfaceType);

export type SegmentInfo = {
  id: string;
  date?: DateTime;
  usage: UsageType;
  laying?: LayingType;
  surfaceType?: SurfaceType;
  scanDevice?: string;
  length: number;
  ductIds: string[];
  width?: number;
  depth: number;
};

export const toSegment = ({ properties }: Feature): SegmentInfo | undefined => {
  if (!properties) return;

  const {
    fid,
    usage_type,
    laying_type,
    scan_date,
    surface_type,
    scan_device,
    length,
    duct_fids,
    width,
    depth,
  } = properties;

  if (
    typeof fid !== 'string' &&
    typeof duct_fids !== 'string' &&
    !isUsageType(usage_type) &&
    isNaN(length)
  ) {
    return;
  }

  return {
    id: fid,
    usage: usage_type,
    length: toNumber(length)!,
    laying: isLayingType(laying_type) ? laying_type : undefined,
    date: scan_date ? DateTime.fromISO(scan_date.substring(0, 10)) : undefined,
    surfaceType: isSurfaceType(surface_type) ? surface_type : undefined,
    scanDevice: scan_device,
    ductIds: duct_fids.split(','),
    width,
    depth,
  };
};

export const getDepthWidthInfo = (
  t: TFunction,
  trenchProfileFilter: 'width' | 'depth',
  trenchProfileRange: number[],
  width?: number,
  depth?: number,
) => {
  if (trenchProfileFilter === 'width') {
    if (!width) {
      return {
        isInRange: null,
        text: t('components.mapSource.trenchProfile.widthNoData'),
      };
    } else if (width < trenchProfileRange[0]) {
      return {
        isInRange: false,
        text: t('components.mapSource.trenchProfile.widthBelowRange', {
          min: trenchProfileRange[0],
        }),
      };
    } else if (width > trenchProfileRange[1]) {
      return {
        isInRange: false,
        text: t('components.mapSource.trenchProfile.widthAboveRange', {
          max: trenchProfileRange[1],
        }),
      };
    }

    return {
      isInRange: true,
      text: t('components.mapSource.trenchProfile.widthInRange'),
    };
  }

  if (!depth) {
    return {
      isInRange: null,
      text: t('components.mapSource.trenchProfile.depthNoData'),
    };
  } else if (depth < trenchProfileRange[0]) {
    return {
      isInRange: false,
      text: t('components.mapSource.trenchProfile.depthBelowRange', {
        min: trenchProfileRange[0],
      }),
    };
  } else if (depth > trenchProfileRange[1]) {
    return {
      isInRange: false,
      text: t('components.mapSource.trenchProfile.depthAboveRange', {
        max: trenchProfileRange[1],
      }),
    };
  }

  return {
    isInRange: true,
    text: t('components.mapSource.trenchProfile.depthInRange'),
  };
};
