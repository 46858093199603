import {
  CheckmarkCircleFilled,
  CircleExclamationMarkFilled,
  CircleInformationFilled,
  WarningFilled,
} from '@deepup/icons';
import { Alert, Paper } from '@mui/material';
import { closeSnackbar, type CustomContentProps } from 'notistack';
import { forwardRef } from 'react';

type SnackbarIconProps = {
  variant: CustomContentProps['variant'];
};

export const SnackbarIcon = ({ variant }: SnackbarIconProps) => {
  if (variant === 'success') {
    return <CheckmarkCircleFilled />;
  }
  if (variant === 'error') {
    return <CircleExclamationMarkFilled />;
  }
  if (variant === 'warning') {
    return <WarningFilled />;
  }

  return <CircleInformationFilled />;
};

export const SnackbarNotification = forwardRef(
  (props: CustomContentProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { variant, message, id, action } = props;
    const validatedVariant = variant === 'default' ? 'info' : variant;

    return (
      <Paper elevation={2} ref={ref}>
        <Alert
          action={<>{action}</>}
          icon={<SnackbarIcon variant={variant} />}
          onClick={() => closeSnackbar(id)}
          severity={validatedVariant}
        >
          {message}
        </Alert>
      </Paper>
    );
  },
);

SnackbarNotification.displayName = 'SnackbarNotification';
