import { labelingProgressApiV1 as labelingProgressApi } from '@deepup/apis';
import {
  DataGrid,
  type DataGridProps,
  type GridFeatureMode,
  type GridPaginationModel,
} from '@mui/x-data-grid';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

export const ProgressTable = ({
  progress,
  paginationModel,
  onPaginationModelChange,
  totalItems,
  isLoading,
  paginationMode,
  onStateChange,
}: {
  progress: labelingProgressApi.GetTotalProgressResponse['items'];
  paginationModel?: GridPaginationModel | undefined;
  onPaginationModelChange?: (model: GridPaginationModel) => void;
  totalItems?: bigint;
  isLoading?: boolean;
  paginationMode: GridFeatureMode;
  onStateChange?: DataGridProps['onStateChange'];
}) => {
  const { t } = useTranslation();
  // Some API clients return undefined while loading
  // Following lines are here to prevent `rowCount` from being undefined during the loading
  const rowCountRef = useRef(totalItems || BigInt(0));

  const rowCount = useMemo(() => {
    if (totalItems !== undefined) {
      rowCountRef.current = totalItems;
    }

    return Number(rowCountRef.current);
  }, [totalItems]);

  const rows = useMemo(() => {
    return progress.map((row) => ({
      id: row.project?.id ?? '',
      projectName: row.project?.name ?? '',
      ...row.progress,
    }));
  }, [progress]);

  return (
    <DataGrid
      columns={[
        {
          field: 'projectName',
          headerName: t('pages.statistics.labelingProgress.dataGrid.projectName'),
          flex: 2,
        },
        {
          field: 'openMeters',
          headerName: t('pages.statistics.labelingProgress.dataGrid.openMeters'),
          valueFormatter: (val) => t('decimal', { val }),
          align: 'right',
          headerAlign: 'right',
          flex: 1,
        },
        {
          field: 'closedMeters',
          headerName: t('pages.statistics.labelingProgress.dataGrid.closedMeters'),
          valueFormatter: (val) => t('decimal', { val }),
          align: 'right',
          headerAlign: 'right',
          flex: 1,
        },
        {
          field: 'layjetMeters',
          headerName: t('pages.statistics.labelingProgress.dataGrid.layjetMeters'),
          valueFormatter: (val) => t('decimal', { val }),
          align: 'right',
          headerAlign: 'right',
          flex: 1,
        },
        {
          field: 'otherMeters',
          headerName: t('pages.statistics.labelingProgress.dataGrid.otherMeters'),
          valueFormatter: (val) => t('decimal', { val }),
          align: 'right',
          headerAlign: 'right',
          flex: 1,
        },
        {
          field: 'totalMeters',
          headerName: t('pages.statistics.labelingProgress.dataGrid.totalMeters'),
          valueFormatter: (val) => t('decimal', { val }),
          align: 'right',
          headerAlign: 'right',
          flex: 1,
        },
      ]}
      disableColumnFilter
      disableColumnMenu
      disableColumnSelector
      disableColumnSorting
      disableRowSelectionOnClick
      initialState={{
        pagination: { paginationModel: { pageSize: 5 } },
      }}
      loading={isLoading}
      onPaginationModelChange={onPaginationModelChange}
      onStateChange={onStateChange}
      pageSizeOptions={[5, 10, 20]}
      paginationMode={paginationMode}
      paginationModel={paginationModel}
      rowCount={paginationMode === 'server' ? rowCount : undefined}
      rows={rows}
      sx={{ minHeight: 200 }}
    />
  );
};
