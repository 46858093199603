import { Divider, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { TabsRouter } from '@components/TabsRouter';

export const Layout = () => {
  const { t } = useTranslation();

  return (
    <Stack p={2} pt={1} spacing={4} sx={{ flex: 1 }}>
      <div>
        <TabsRouter
          items={[
            {
              label: t('pages.statistics.constructionProgress.title'),
              to: '/statistics/construction-progress',
            },
            {
              label: t('pages.statistics.labelingProgress.title'),
              to: '/statistics/labeling-progress',
            },
          ]}
          sx={{ position: 'relative', zIndex: 1 }}
        />
        <Divider
          sx={{ position: 'relative', top: '-1px', zIndex: 0, marginLeft: -2, marginRight: -2 }}
        />
      </div>
      <Outlet />
    </Stack>
  );
};
