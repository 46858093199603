import {
  Autocomplete,
  TextField,
  FormControl,
  Paper,
  type PaperProps,
  Stack,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { type ProjectEntity, useApiProjects } from '@hooks/useApiProjects';

type Props = {
  project: ProjectEntity | null;
  setProject: (value: ProjectEntity | null) => void;
};

export const ProjectAutocomplete = ({ project, setProject }: Props) => {
  const { t } = useTranslation();
  const { data: projectEnvelope, isLoading } = useApiProjects();
  const projects = projectEnvelope?.data || [];
  const [inputValue, setInputValue] = useState('');
  const theme = useTheme();

  return (
    <Stack
      component="div"
      flex="auto"
      sx={{
        paddingRight: project
          ? {
              xs: theme.spacing(4),
              sm: theme.spacing(8),
              md: theme.spacing(12),
              lg: theme.spacing(16),
            }
          : 0,
        minWidth: 240,
      }}
    >
      <FormControl fullWidth>
        <Autocomplete
          PaperComponent={(props: PaperProps) => (
            <Paper {...props} elevation={6}>
              {props.children}
            </Paper>
          )}
          getOptionLabel={(option) => option.name}
          inputValue={inputValue}
          loading={isLoading}
          onChange={(_, newValue) => {
            setProject(newValue);
          }}
          onInputChange={(_, newInputValue) => {
            setInputValue(newInputValue);
          }}
          options={projects}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('components.filterBox.projectSelect.label')}
              variant="outlined"
            />
          )}
          selectOnFocus
          value={project}
        />
      </FormControl>
    </Stack>
  );
};
