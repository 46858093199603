import { useTranslation } from 'react-i18next';

import { useApiProjects } from '@hooks/useApiProjects';
import { useFilters } from '@hooks/useFilters';

import { MultiSelectInversed } from './fields/MultiSelect';

export const ProjectMultiSelect = () => {
  const { data: projectEnvelope } = useApiProjects();
  const { projectList, setProjectList } = useFilters();
  const { t } = useTranslation();

  const allProjects = projectEnvelope?.data ?? [];
  const unselectedProjects = allProjects.filter((p) => projectList.includes(p.id));

  return (
    <MultiSelectInversed
      items={allProjects}
      label={t('components.filterBox.projectSelect.label')}
      setUnselectedItems={(nextProjects) => {
        setProjectList(nextProjects.map((p) => p.id));
      }}
      unselectedItems={unselectedProjects}
    />
  );
};
