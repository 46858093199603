import { FilterOutline } from '@deepup/icons';
import { CardContent, Grid, Paper, Skeleton, Stack, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import type { MapboxGeoJSONFeature } from 'mapbox-gl';
import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { ProjectSelect } from '@components/FilterBox/ProjectSelect';
import { RangeSlider } from '@components/FilterBox/fields/RangeSlider';
import {
  useLayingTypeReadable,
  useSurfaceTypeReadable,
  useUsageTypeReadable,
} from '@components/SegmentInfoCard';
import { SidebarHeader, SidebarHeaderText, SidebarSection } from '@components/Sidebar';
import { useApiProjects } from '@hooks/useApiProjects';
import type { FilterValues } from '@hooks/useFilters';
import { useMinDateFromFeatures } from '@hooks/useMinDateFromFeatures';
import { useScanDevicesFromFeatures } from '@hooks/useScanDevicesFromFeatures';
import type { LayingType, SurfaceType, UsageType } from '@utils/segmentMapping';

import { DateRangePicker } from './fields/DateRangePicker';
import { SelectBoxMultiInversed } from './fields/SelectBoxMulti';

const minSliderValue = 0;
const maxSliderValue = 100;
const drawerWidth = 350;

export type FilterBoxBillingProps = {
  filters: FilterValues;
  features: MapboxGeoJSONFeature[];
};

export const FilterBoxBilling = ({ filters, features }: FilterBoxBillingProps) => {
  const { t } = useTranslation();
  const { usageTypeReadable } = useUsageTypeReadable();
  const { layingTypeReadable } = useLayingTypeReadable();
  const { surfaceTypeReadable } = useSurfaceTypeReadable();

  const {
    dateRange,
    setDateRange,
    scanDevices,
    setScanDevices,
    projectId,
    setProjectId,
    usageTypes,
    setUsageTypes,
    layingTypes,
    setLayingTypes,
    surfaceTypes,
    setSurfaceTypes,
    reset,
    width,
    setWidth,
    depth,
    setDepth,
  } = filters;

  const filteredScanDevices = useScanDevicesFromFeatures(features, scanDevices).map((i) => i.name);
  const { data: projectEnvelope, status } = useApiProjects();
  const minDate = useMinDateFromFeatures(features);

  if (status === 'pending') {
    return <FilterBoxSkeleton />;
  }

  if (status === 'error') {
    return (
      <Scaffold>
        <Typography>{t('components.filterBoxBilling.projectLoadingError')}</Typography>
      </Scaffold>
    );
  }

  const isDisabled = !features.length;

  const setProjectIdAndResetOthers = (
    projectId: string | null | ((projectId: string | null) => string | null),
  ) => {
    reset();

    setProjectId(projectId ? projectId : null);
  };

  const projects = [
    { id: '09446f49-5e50-4f1c-86c9-026afdcafb14', name: 'Testprojekt' }, // TODO remove test project
    ...(projectEnvelope?.data ?? []),
  ];

  return (
    <Scaffold>
      <Stack direction="column" spacing="20px">
        <SidebarSection>
          <FilterOutline /> {t('components.filterBoxBilling.filterSubHeading')}
        </SidebarSection>

        <ProjectSelect
          projectId={projectId}
          projects={projects}
          setProjectId={setProjectIdAndResetOthers}
        />

        <Stack direction="column" spacing="12px">
          <DateRangePicker
            dateProp="scan_date"
            dateRange={dateRange}
            disabled={isDisabled}
            minDate={minDate}
            setDateRange={setDateRange}
          />
        </Stack>

        <SelectBoxMultiInversed
          availableItems={filteredScanDevices}
          disabled={!filteredScanDevices.length}
          items={filteredScanDevices}
          label={t('components.filterBox.scanDeviceSelect.label')}
          setItems={setScanDevices}
        />

        <SelectBoxMultiInversed
          availableItems={[
            ...new Set(
              [
                ...usageTypes,
                ...features
                  .map((feature) => feature.properties?.usage_type ?? 'undefined')
                  .sort(Intl.Collator().compare),
              ].sort(Intl.Collator().compare),
            ),
          ]}
          disabled={isDisabled}
          items={usageTypes}
          label={t('components.filterBoxBilling.usageSelectLabel')}
          setItems={setUsageTypes}
          transformLabel={(label) => usageTypeReadable[label as UsageType] ?? label}
        />

        <SelectBoxMultiInversed
          availableItems={[
            ...new Set(
              [
                ...layingTypes,
                ...features.map((feature) => feature.properties?.laying_type ?? 'undefined'),
              ].sort(Intl.Collator().compare),
            ),
          ]}
          disabled={isDisabled}
          items={layingTypes}
          label={t('components.filterBoxBilling.layingTypeSelectLabel')}
          setItems={setLayingTypes}
          transformLabel={(label) => layingTypeReadable[label as LayingType] ?? label}
        />

        <SelectBoxMultiInversed
          availableItems={[
            ...new Set(
              [
                ...surfaceTypes,
                ...features.map((feature) => feature.properties?.surface_type ?? 'undefined'),
              ].sort(Intl.Collator().compare),
            ),
          ]}
          disabled={isDisabled}
          items={surfaceTypes}
          label={t('components.filterBoxBilling.surfaceTypeSelectLabel')}
          setItems={setSurfaceTypes}
          transformLabel={(label) => surfaceTypeReadable[label as SurfaceType] ?? label}
        />
        <Stack direction="column" spacing="16px" sx={{ paddingX: '8px' }}>
          <RangeSlider
            allowedRange={{ min: minSliderValue, max: maxSliderValue }}
            disabled={isDisabled}
            label={t('components.filterBoxBilling.depthSliderLabel')}
            range={depth}
            setRange={setDepth}
          />
          <RangeSlider
            allowedRange={{ min: minSliderValue, max: maxSliderValue }}
            disabled={isDisabled}
            label={t('components.filterBoxBilling.widthSliderLabel')}
            range={width}
            setRange={setWidth}
          />
        </Stack>
      </Stack>
    </Scaffold>
  );
};

const FilterBoxSkeleton = () => {
  return (
    <Scaffold>
      <Stack direction="column" padding="16px" spacing="20px">
        {/* filter subheader */}
        <Skeleton height={24} variant="rounded" width={70} />
        {/* project selection */}
        <Skeleton height={48} variant="rounded" width="100%" />
        {/* date */}
        <Stack direction="column" spacing="12px">
          <Skeleton height={48} variant="rounded" width="100%" />
          <Grid container direction="row" gap="8px">
            <Skeleton height={48} variant="rounded" width="calc(50% - 4px)" />
            <Skeleton height={48} variant="rounded" width="calc(50% - 4px)" />
          </Grid>
        </Stack>
        {/* select boxes */}
        <Skeleton height={48} variant="rounded" width="100%" />
        <Skeleton height={48} variant="rounded" width="100%" />
        <Skeleton height={48} variant="rounded" width="100%" />
        <Skeleton height={48} variant="rounded" width="100%" />
        {/* sliders */}
        <Stack direction="column" spacing="16px" sx={{ paddingX: '8px' }}>
          <Skeleton height={72} variant="rounded" width="100%" />
          <Skeleton height={72} variant="rounded" width="100%" />
        </Stack>
      </Stack>
    </Scaffold>
  );
};

const Scaffold = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation();

  return (
    <LocalizationProvider adapterLocale="de" dateAdapter={AdapterLuxon}>
      <Paper elevation={0} square sx={{ width: drawerWidth, overflow: 'auto' }}>
        <SidebarHeader>
          <SidebarHeaderText>
            {t('components.filterBoxBilling.sidebarHeaderText')}
          </SidebarHeaderText>
        </SidebarHeader>
        <CardContent>{children}</CardContent>
      </Paper>
    </LocalizationProvider>
  );
};
