import { constructionProgressApiV1 as constructionProgressApi } from '@deepup/apis';
import { CircleInformationOutline } from '@deepup/icons';
import { Stack, Tooltip } from '@mui/material';
import {
  DataGrid,
  type GridColDef,
  type GridColumnGroupingModel,
  type GridColumnHeaderParams,
  type GridColumnGroupHeaderParams,
  type DataGridProps,
} from '@mui/x-data-grid';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useApiTotalProgress } from '@hooks/useApiConstructionProgress';
import { useFilters } from '@hooks/useFilters';

type Progress = constructionProgressApi.Progress;

type Header = GridColumnHeaderParams<Progress>;
type Group = GridColumnGroupHeaderParams;

const HeaderWithDescription = (params: Header | Group) => {
  const headerName = (params as Header).colDef?.headerName ?? (params as Group).headerName ?? '';
  const description = (params as Header).colDef?.description ?? (params as Group).description ?? '';

  return (
    <Tooltip
      title={
        <Stack gap={1}>
          {description.split('\n').map((line, i) => (
            <span key={i}>{line}</span>
          ))}
        </Stack>
      }
    >
      <Stack alignItems="center" direction="row" gap={1}>
        {headerName}
        <CircleInformationOutline fill="currentColor" />
      </Stack>
    </Tooltip>
  );
};

const useTableDefs = () => {
  const { t } = useTranslation();

  const groupingModel: GridColumnGroupingModel = [
    {
      groupId: 'meters-group',
      headerName: t('pages.statistics.constructionProgress.progressTable.metersGroup.title'),
      description: t('pages.statistics.constructionProgress.progressTable.metersGroup.info'),
      children: [
        { field: 'prelabeledMeters' },
        { field: 'labeledMeters' },
        { field: 'trenchMeters' },
      ],
      renderHeaderGroup: HeaderWithDescription,
    },
  ];

  const colDefs: GridColDef<constructionProgressApi.Progress>[] = [
    {
      field: 'name',
      valueGetter: (_, row) => row.project?.name,
      headerName: t('pages.statistics.constructionProgress.progressTable.name'),
      flex: 1,
    },
    {
      field: 'scanDevices',
      valueGetter: (_, row) => row.scanDevices?.map((s) => s.serialNumber).join(', '),
      headerName: t('pages.statistics.constructionProgress.progressTable.scanners'),
      flex: 1,
    },
    {
      field: 'numberOfHouseLeads',
      headerName: t('pages.statistics.constructionProgress.progressTable.nrHouseLeads.title'),
      description: t('pages.statistics.constructionProgress.progressTable.nrHouseLeads.info'),
      renderHeader: HeaderWithDescription,
      flex: 1,
      type: 'number',
      cellClassName: 'numberOfHouseLeads',
      headerClassName: 'numberOfHouseLeads',
    },
    {
      field: 'prelabeledMeters',
      headerName: t('pages.statistics.constructionProgress.progressTable.aiPrediction'),
      valueFormatter: (val) => t('decimal', { val }),
      flex: 1,
      type: 'number',
      cellClassName: 'prelabeledMeters',
      headerClassName: 'prelabeledMeters',
    },
    {
      field: 'labeledMeters',
      headerName: t('pages.statistics.constructionProgress.progressTable.qualityAssured'),
      valueFormatter: (val) => t('decimal', { val }),
      flex: 1,
      type: 'number',
      cellClassName: 'labeledMeters',
      headerClassName: 'labeledMeters',
    },
    {
      field: 'trenchMeters',
      headerName: t('pages.statistics.constructionProgress.progressTable.total.title'),
      description: t('pages.statistics.constructionProgress.progressTable.total.info'),
      renderHeader: HeaderWithDescription,
      valueFormatter: (val) => t('decimal', { val }),
      flex: 1,
      type: 'number',
      cellClassName: 'trenchMeters',
    },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => ({ colDefs, groupingModel }), [t]);
};

const tableStyles: DataGridProps['sx'] = (theme) => ({
  minHeight: 250,
  '& .numberOfHouseLeads': {
    backgroundColor: theme.deepupColors.gold[100],
  },
  '& .prelabeledMeters': {
    backgroundColor: theme.deepupColors.green[50],
  },
  '& .labeledMeters': {
    backgroundColor: theme.deepupColors.green[100],
  },
});

export const ProgressTableClient = ({
  progress,
  isFetching = false,
  onStateChange,
}: {
  progress: Progress[];
  isFetching?: boolean;
  onStateChange?: DataGridProps['onStateChange'];
}) => {
  const { colDefs, groupingModel } = useTableDefs();

  return (
    <DataGrid
      columnGroupingModel={groupingModel}
      columns={colDefs}
      disableColumnFilter
      disableColumnMenu
      disableColumnSelector
      disableColumnSorting
      getRowId={(row) => row.project!.id}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 12 },
        },
      }}
      loading={isFetching}
      onStateChange={onStateChange}
      pageSizeOptions={[12, 24, 48]}
      pagination
      paginationMode="client"
      rows={progress}
      sx={tableStyles}
    />
  );
};

export const ProgressTableServer = () => {
  const { dateRange, projectList, scanDevices } = useFilters();
  const { progress, totalItems, pageSize, pageNr, paginate, isFetching, error } =
    useApiTotalProgress(projectList, scanDevices, dateRange);
  const { colDefs, groupingModel } = useTableDefs();
  const { t } = useTranslation();

  useEffect(() => {
    if (!error) return;
    enqueueSnackbar(
      t('pages.statistics.constructionProgress.fetchingError', {
        error: error.body?.message,
      }),
      { variant: 'error' },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <DataGrid
      columnGroupingModel={groupingModel}
      columns={colDefs}
      getRowId={(row) => row.project!.id}
      loading={isFetching || !progress?.length}
      onPaginationModelChange={paginate}
      pageSizeOptions={[pageSize]}
      pagination
      paginationMode="server"
      paginationModel={{ page: pageNr, pageSize }}
      rowCount={totalItems}
      rowSelection={false}
      rows={progress}
      sx={tableStyles}
    />
  );
};
