import { Badge, type BadgeProps, Chip, type ChipProps, Tooltip } from '@mui/material';
import type { IFlagsmithFeature } from 'flagsmith/types';
import type { TFunction } from 'i18next';
import { DateTime } from 'luxon';
import type { Invalid, Valid } from 'luxon/src/_util';
import type { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

export type FlagData = null | {
  status: string | null;
  releaseDate: DateTime<Valid> | DateTime<Invalid> | null;
  color: ValidColor | null;
  helpText: string | null;
};

export type ReleaseStatus = null | 'pre' | 'new' | 'post';

export const validColors = [
  'default',
  'error',
  'primary',
  'secondary',
  'info',
  'success',
  'warning',
] as const;

export type ValidColor = (typeof validColors)[number];

export const getFlagData = (flag?: IFlagsmithFeature): FlagData => {
  if (!flag) {
    return null;
  }

  try {
    const parsed = JSON.parse(String(flag?.value));

    return {
      status: parsed?.status ? String(parsed.status) : null,
      releaseDate: parsed?.releaseDate ? DateTime.fromISO(parsed?.releaseDate) : null,
      color:
        parsed?.color && validColors.includes(String(parsed.color) as ValidColor)
          ? (String(parsed.color) as ValidColor)
          : null,
      helpText: parsed?.helpText ? String(parsed.helpText) : null,
    };
  } catch (_) {
    return null;
  }
};

export const getReleaseStatus = (flagData: FlagData, now: DateTime): ReleaseStatus => {
  return !flagData?.releaseDate || !flagData.releaseDate.isValid
    ? null
    : now < flagData.releaseDate
      ? 'pre'
      : now >= flagData.releaseDate && now < flagData.releaseDate.plus({ days: 14 })
        ? 'new'
        : 'post';
};

export const getBadgeText = (flagData: FlagData, releaseStatus: ReleaseStatus, t: TFunction) => {
  if (releaseStatus === null) {
    return flagData?.status ?? '';
  }
  if (releaseStatus === 'pre') {
    return flagData?.status ?? t('components.featureFlag.badge.internal');
  }
  if (releaseStatus === 'new') {
    return t('components.featureFlag.badge.new');
  }

  return '';
};

export const getBadgeVisibility = (flagData: FlagData, releaseStatus: ReleaseStatus): boolean => {
  if (releaseStatus === 'pre' || releaseStatus === 'new') {
    return true;
  }
  if (releaseStatus === 'post') {
    return false;
  }

  return !!flagData?.status;
};

export const getBadgeColor = (flagData: FlagData, releaseStatus: ReleaseStatus): ValidColor => {
  if (releaseStatus === null || releaseStatus === 'pre') {
    return flagData?.color ?? 'error';
  }
  if (releaseStatus === 'new') {
    return 'info';
  }

  return 'default';
};

const WrapWithTooltip = ({
  helpText,
  children,
  isVisible,
}: {
  helpText?: string | null;
  children: ReactElement;
  isVisible: boolean;
}) => {
  return helpText && isVisible ? <Tooltip title={helpText}>{children}</Tooltip> : children;
};

/**
 * Returns a predefined Badge element
 * Usage:
 * const flags = useFlags(['my_flag']);
 * const BadgeMyFlag = useFeatureBadge(flags['my_flag']);
 * return <BadgeMyFlag>Feature Text</BadgeMyFlag>;
 * @param flag Takes a IFlagsmithFeature flag
 * @returns A Badge JSX.Element
 */
export const useFeatureBadge = (flag: IFlagsmithFeature) => {
  const { t } = useTranslation();
  const flagData = getFlagData(flag);
  const releaseStatus = getReleaseStatus(flagData, DateTime.now());
  const text = getBadgeText(flagData, releaseStatus, t);
  const isVisible = flag.enabled && getBadgeVisibility(flagData, releaseStatus);
  const color = getBadgeColor(flagData, releaseStatus);

  // It works for wrapping basic text elements
  // If you need to wrap other components like a selectBox you can just override the sx:
  // <BadgeMyFlag
  //   sx={{
  //     position: 'absolute',
  //     right: '0px',
  //     margin: '15px 25px 0 0',
  //     '& .MuiBadge-badge': {
  //       fontSize: '9px',
  //       padding: '4px',
  //       borderRadius: '7px',
  //       height: 'auto',
  //       top: '-12px',
  //       right: '0px',
  //       position: 'absolute',
  //       textTransform: 'uppercase',
  //     },
  //   }}
  // >
  //   <SelectBox />
  // </BadgeMyFlag>
  return function FeatureBadge(props: BadgeProps) {
    return (
      <Badge
        badgeContent={
          <WrapWithTooltip helpText={flagData?.helpText} isVisible={isVisible}>
            <span>{text}</span>
          </WrapWithTooltip>
        }
        color={color}
        invisible={!isVisible}
        sx={{
          '& .MuiBadge-badge': {
            fontSize: '9px',
            padding: '4px',
            borderRadius: '7px',
            height: 'auto',
            top: '-7px',
            right: '4px',
            textTransform: 'uppercase',
          },
        }}
        {...props}
      />
    );
  };
};

/**
 * Returns a predefined Chip element
 * Usage:
 * const flags = useFlags(['my_flag']);
 * const ChipMyFlag = useFeatureChip(flags['my_flag']);
 * return <ChipMyFlag />;
 * @param flag Takes a IFlagsmithFeature flag
 * @returns A Chip JSX.Element
 */
export const useFeatureChip = (flag: IFlagsmithFeature) => {
  const { t } = useTranslation();
  const flagData = getFlagData(flag);
  const releaseStatus = getReleaseStatus(flagData, DateTime.now());
  const text = getBadgeText(flagData, releaseStatus, t);
  const isVisible = flag.enabled && getBadgeVisibility(flagData, releaseStatus);
  const color = getBadgeColor(flagData, releaseStatus);

  return function FeatureChip(props: ChipProps) {
    if (!isVisible) {
      return null;
    }

    return (
      <WrapWithTooltip helpText={flagData?.helpText} isVisible>
        <Chip color={color} label={text} sx={{ textTransform: 'uppercase' }} {...props} />
      </WrapWithTooltip>
    );
  };
};
