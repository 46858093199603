import { useTheme } from '@mui/material';
import { useEffect } from 'react';
import { Layer } from 'react-map-gl';
import { useParams } from 'react-router-dom';

import { type VectorLayerProps } from '@components/MapSource/VectorLayerProps';
import { useFilters } from '@hooks/useFilters';
import { useMapFeatureState } from '@hooks/useMapFeatureState';
import { useMapHoverFeature } from '@hooks/useMapHoverFeature';
import { useMapSelectFeatures } from '@hooks/useMapSelectFeatures';
import { useNavigateExtended } from '@hooks/useNavigateExtended';
import {
  isScanExpression as getIsScanExpression,
  isHoveredExpression,
  projectCloseupThreshold,
  filterOldScansExpression,
} from '@utils/mapboxExpressions';

export const OldScans = ({ source, sourceLayer }: VectorLayerProps) => {
  const navigate = useNavigateExtended();
  const { scanId, elementType } = useParams();
  const theme = useTheme();

  const layerId = 'oldscans-layer';
  const { selectedFeature } = useMapSelectFeatures([layerId]);
  const { feature: hoveredFeature } = useMapHoverFeature(layerId);

  useMapFeatureState(hoveredFeature, { hover: true });

  const filters = useFilters();

  const filter = filterOldScansExpression(filters);

  useEffect(() => {
    const scanId = selectedFeature?.properties?.scan_id;

    if (scanId) {
      navigate(`scan/${scanId}/trajectory/top-down-view`, {
        preserveSearch: true,
        preserveHash: true,
      });
    }
    // https://github.com/remix-run/react-router/issues/7634
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFeature]);

  const isScanExpression = getIsScanExpression(scanId, elementType, 'trajectory');

  const hideOldScans = filters.surfaceClassification.includes('noTrajectory');

  if (!filters.showScans || hideOldScans) {
    return null;
  }

  return (
    <Layer
      filter={filter}
      id={layerId}
      minzoom={projectCloseupThreshold}
      paint={{
        'circle-color': theme.palette.primary.main,
        'circle-radius': ['case', isHoveredExpression, 10, isScanExpression, 10, 8],
        'circle-pitch-scale': 'map',
        'circle-stroke-width': 3,
        'circle-stroke-color': '#ffffff',
      }}
      source={source}
      source-layer={sourceLayer}
      type="circle"
    />
  );
};
