import { photoApiV1 as photoApi, photoClientApiV1 as photoClientApi } from '@deepup/apis';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useState } from 'react';

import { useGrpcRestTransport } from '@hooks/useGrpcRestTransport';
import { fromTimestamp } from '@utils/timeFormatting';

export const filterUsablePackages = (
  items: photoApi.PhotoPackage[],
  releaseDateMs: number,
): photoApi.PhotoPackage[] => {
  return items.filter((item) => {
    if (item.lastModifiedAt === undefined || item.createdAt === undefined) {
      return false;
    }
    // filter out infinitely pending packages caused by silently crashed argo workflows
    const PAST_24_HOURS = 1000 * 60 * 60 * 24;
    const isOld = fromTimestamp(item.lastModifiedAt).toMillis() < Date.now() - PAST_24_HOURS;
    const isLost = item.status === photoApi.PhotoPackageStatus.PENDING && isOld;

    // filter out packages preceding release
    const isCreatedBeforeRelease = fromTimestamp(item.createdAt).toMillis() < releaseDateMs;

    return !isLost && !isCreatedBeforeRelease;
  });
};

// TODO: remove this after one week (16.12.24)
// packages created in the testing phase must be invisible for users
const DATE_FILTER = new Date('2024-12-11T09:00:00.000Z').getTime();

export const usePhotoPackage = (projectId: string, releaseDateMs = DATE_FILTER) => {
  const [isPollingActive, setPollingActive] = useState(true);
  const transport = useGrpcRestTransport();
  const photoClient = new photoClientApi.PhotoServiceClient(transport);

  return useQuery({
    queryKey: ['photoClient.listPhotoPackages', projectId, releaseDateMs],
    queryFn: async () => {
      const packages = await photoClient.listPhotoPackages({ projectId, includeExpired: false })
        .response;

      const usablePackages = filterUsablePackages(packages.items, releaseDateMs);

      if (!usablePackages.length) {
        setPollingActive(false);

        return null;
      }

      // sort packages by creation date
      const [latestPackage, ...pastPackages] = usablePackages.sort((a, b) => {
        if (a.createdAt && b.createdAt) {
          return fromTimestamp(b.createdAt).toMillis() - fromTimestamp(a.createdAt).toMillis();
        }

        return -1;
      });

      // exit if the most recent package is ready for downloading
      if (latestPackage.status === photoApi.PhotoPackageStatus.DONE) {
        const pollingState = false;

        setPollingActive(pollingState);

        return {
          photoPackage: latestPackage,
          isPollingActive: pollingState,
        };
      }

      // provide most recent downloadable package along with the pending one
      const availablePackage = pastPackages.find(
        (item) => item.status === photoApi.PhotoPackageStatus.DONE,
      );

      const pollingState = latestPackage.status === photoApi.PhotoPackageStatus.PENDING;

      setPollingActive(pollingState);

      return {
        photoPackage: availablePackage,
        isPollingActive: pollingState,
      };
    },
    refetchInterval: isPollingActive ? 2000 : false,
  });
};

export const useDownloadLink = (packageId?: string) => {
  const transport = useGrpcRestTransport();
  const photoClient = new photoClientApi.PhotoServiceClient(transport);

  return useQuery({
    queryKey: ['photoClient.downloadPhotoPackage', packageId],
    queryFn: async () => {
      if (!packageId) {
        return null;
      }

      const downloadLink = await photoClient.downloadPhotoPackage({ id: packageId }).response;

      return {
        downloadLink,
      };
    },
    enabled: !!packageId,
  });
};

export const useCreatePackage = () => {
  const transport = useGrpcRestTransport();

  const mutation = useMutation<
    photoApi.PhotoPackage,
    { error: { errors: string[] } },
    photoApi.CreatePhotoPackageRequest
  >({
    mutationFn: ({ projectId, photoIds }) => {
      const photoClient = new photoClientApi.PhotoServiceClient(transport);

      return photoClient.createPhotoPackage({ projectId, photoIds }).response;
    },
  });

  return mutation;
};
