import { GlobalHeader } from '@deepup/global-header';
import { MapOutline, PhotoOutline, ProjectCollectionOutline, GraphOutline } from '@deepup/icons';
import { AppBar, Stack, Toolbar, useTheme } from '@mui/material';
import { useFlags } from 'flagsmith/react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { FiltersProvider } from '@components/FiltersProvider';
import { TabsRouter } from '@components/TabsRouter';
import { useConfig } from '@hooks/useConfig';
import { useFeatureBadge } from '@hooks/useFeatureBadge';

export const Layout = () => {
  const { t, i18n } = useTranslation();
  const { layoutContainerStyle } = useConfig();
  const theme = useTheme();
  const flags = useFlags(['statistics_enabled', 'cockpit_migration_enabled', 'oldscans_enabled']);

  const flagStatistics = flags['statistics_enabled'];
  const BadgeStatistics = useFeatureBadge(flagStatistics);

  const flagCockpitMigration = flags['cockpit_migration_enabled'];
  const BadgeCockpitMigration = useFeatureBadge(flagCockpitMigration);

  const iconProps = {
    fill: 'currentColor',
    height: '24px',
    width: '24px',
  };

  const tabItems = [];

  if (flagCockpitMigration.enabled || flagStatistics.enabled) {
    tabItems.push({
      label: t('pages.layout.mapTabLabel'),
      to: '/map',
      icon: <MapOutline {...iconProps} />,
    });
  }

  if (flagCockpitMigration.enabled) {
    tabItems.push(
      {
        label: <BadgeCockpitMigration>{t('pages.layout.photoTabLabel')}</BadgeCockpitMigration>,
        to: '/photo',
        icon: <PhotoOutline {...iconProps} />,
      },
      {
        label: <BadgeCockpitMigration>{t('pages.layout.projectTabLabel')}</BadgeCockpitMigration>,
        to: '/project',
        icon: <ProjectCollectionOutline {...iconProps} />,
      },
    );
  }

  if (flagStatistics.enabled) {
    tabItems.push({
      label: <BadgeStatistics>{t('pages.statistics.title')}</BadgeStatistics>,
      to: '/statistics',
      icon: <GraphOutline {...iconProps} />,
    });
  }

  return (
    <FiltersProvider>
      <GlobalHeader
        language={i18n.language as 'de' | 'en' | undefined}
        languageSelectors={[{ key: 'de' }, { key: 'en' }]}
        onChangeLanguage={(selectedLanguage) => {
          i18n.changeLanguage(selectedLanguage);
        }}
        showLanguageToggle
        showThemeToggle
        title={t('title')}
      />
      {(flagCockpitMigration.enabled || flagStatistics.enabled) && (
        <AppBar
          component="nav"
          sx={(theme) => ({
            zIndex: theme.zIndex.drawer + 1,
            boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14)',
            borderRadius: 0,
            padding: 0,
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.getContrastText(theme.palette.background.paper),
            borderTop: `1px solid ${theme.palette.divider}`,
            // top because of fixed global header
            top: { xs: '56px', sm: '80px' },
          })}
        >
          <Toolbar sx={{ gap: '20px' }}>
            <TabsRouter
              items={tabItems}
              // same height as Toolbar
              sx={{ height: { xs: '56px', sm: '64px' } }}
            />
          </Toolbar>
        </AppBar>
      )}
      <Stack
        sx={{
          minHeight: '100%',
          background: theme.palette.background.default,
          color: theme.palette.text.primary,
          ...layoutContainerStyle,
        }}
      >
        <Outlet />
      </Stack>
    </FiltersProvider>
  );
};
