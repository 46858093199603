import { RpcRestError } from '@deepup/api-utils';
import { Divider, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { enqueueSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { DateRangePicker } from '@components/FilterBox/fields/DateRangePicker';
import { MultiSelect } from '@components/FilterBox/fields/MultiSelect';
import { SearchNotPossible } from '@components/SearchNotPossible';
import { useApiProjects } from '@hooks/useApiProjects';
import { useFilters } from '@hooks/useFilters';

import { Charts } from './Charts';
import { ProgressTableServer } from './ProgressTable';

export const Page = () => {
  const { t } = useTranslation();

  const { dateRange, setDateRange, projectList, setProjectList } = useFilters();

  const { data: { data: projectsForUser } = { data: [] }, error: projectsFetchError } =
    useApiProjects();

  // TODO enable scan device filtering in next iteration!
  // const { data: { items: scannersUsedInProjects } = { items: [] }, error: scannersFetchError } =
  //   useApiDeviceManagement(projectList, dateRange);

  const error = projectsFetchError; // ?? scannersFetchError; // TODO enable scan device filtering in next iteration!

  useEffect(() => {
    if (!error) return;
    const msg = projectsFetchError
      ? t('common.errors.fetchingProjects', {
          error: (error as typeof projectsFetchError).message,
        })
      : t('pages.statistics.constructionProgress.fetchingError', {
          error: (error as RpcRestError).body?.message,
        });

    enqueueSnackbar(msg, { variant: 'error' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (dateRange.from && dateRange.to) return;
    // set default date range to last 2 days in the beginning if it has not been set
    const now = DateTime.now();

    setDateRange({
      from: now.minus({ days: 2 }),
      to: now,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedProjects = projectsForUser.filter((p) => projectList.includes(p.id));
  // TODO enable scan device filtering in next iteration!
  // const scannerItems = scannersUsedInProjects.map((s) => ({ id: s.id, name: s.serialNumber }));
  // const selectedScannerItems = scannerItems.filter((s) => scanDevices.includes(s.id));

  return (
    <Stack gap={4}>
      <Typography variant="subtitle2">{t('pages.statistics.constructionProgress.info')}</Typography>
      <Stack direction="row" gap={2}>
        <MultiSelect
          items={projectsForUser}
          label={t('components.filterBox.projectSelect.label')}
          selectedItems={selectedProjects}
          setSelectedItems={(nextProjects) => {
            setProjectList(nextProjects.map((p) => p.id));
          }}
        />
        <DateRangePicker dateRange={dateRange} setDateRange={setDateRange} />
        {/* TODO enable scan device filtering in next iteration! */}
        {/*<MultiSelect*/}
        {/*  items={scannerItems}*/}
        {/*  label={t('components.filterBox.scanDeviceSelect.label')}*/}
        {/*  selectedItems={selectedScannerItems}*/}
        {/*  setSelectedItems={(nextDevices) => {*/}
        {/*    setScanDevices(nextDevices.map((p) => p.id));*/}
        {/*  }}*/}
        {/*/>*/}
      </Stack>
      {!projectList.length ? (
        // {!projectList.length || !scanDevices.length ? ( // TODO enable scan device filtering in next iteration!
        <SearchNotPossible>
          {t('pages.statistics.constructionProgress.searchNotPossible')}
        </SearchNotPossible>
      ) : (
        <>
          <ProgressTableServer />
          <Divider />
          <Charts />
        </>
      )}
    </Stack>
  );
};
