import { TrenchDepthOutline, TrenchWidthOutline } from '@deepup/icons';
import { Chip, Slider, Stack, Typography } from '@mui/material';
import { useFlags } from 'flagsmith/react';
import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { CheckBoxGroupInversed } from '@components/FilterBox/fields/CheckBoxGroup';
import { ToggleButtonGroup } from '@components/FilterBox/fields/ToggleButtonGroup';
import {
  useLayingTypeReadable,
  useSurfaceTypeReadable,
  useUsageTypeReadable,
} from '@components/SegmentInfoCard';
import { useFeatureBadge, useFeatureChip } from '@hooks/useFeatureBadge';
import { useFilters, type TrassFilter, TrassFilterEnum } from '@hooks/useFilters';
import { useMapFeatures } from '@hooks/useMapFeatures';
import { getNumberSelected, isDefined } from '@utils/core';
import type { LayingType, SurfaceType, UsageType } from '@utils/segmentMapping';

import { AccordionWithCheckbox } from '../Accordion';
import { Tabs, type TabsProps } from '../Tabs';

export const TrassesFilters = ({ title }: { title: ReactNode }) => {
  const { t } = useTranslation();
  const { usageTypeReadable } = useUsageTypeReadable();
  const { layingTypeReadable } = useLayingTypeReadable();
  const { surfaceTypeReadable } = useSurfaceTypeReadable();

  const {
    showTrasses,
    setShowTrasses,
    usageTypes,
    setUsageTypes,
    layingTypes,
    setLayingTypes,
    surfaceTypes,
    setSurfaceTypes,
    activeTrassFilter,
    setActiveTrassFilter,
    trenchProfileFilter,
    setTrenchProfileFilter,
    trenchProfileRange,
    setTrenchProfileRange,
  } = useFilters();

  const features = useMapFeatures({
    source: 'trass-source',
    sourceLayer: 'trass_segmented',
    filter: ['to-boolean', showTrasses],
  });

  const flags = useFlags(['filter_trench_profile_enabled']);

  const flagTrenchProfile = flags['filter_trench_profile_enabled'];
  const BadgeTrenchProfile = useFeatureBadge(flagTrenchProfile);
  const ChipTrenchProfile = useFeatureChip(flagTrenchProfile);

  const availableUsageTypes = [
    ...new Set([
      ...features.map((f) => f.properties?.usage_type).filter(isDefined<UsageType>),
      ...usageTypes,
    ]),
  ];

  const availableLayingTypes = [
    ...new Set([
      ...features.map((f) => f.properties?.laying_type).filter(isDefined<LayingType>),
      ...layingTypes,
    ]),
  ];

  const availableSurfaceTypes = [
    ...new Set([
      ...features.map((f) => f.properties?.surface_type).filter(isDefined<SurfaceType>),
      ...surfaceTypes,
    ]),
  ];

  const currentFilterTypes =
    activeTrassFilter === 'SURFACE'
      ? surfaceTypes
      : activeTrassFilter === 'USAGE'
        ? usageTypes
        : activeTrassFilter === 'LAYING'
          ? layingTypes
          : [];

  const availableFilterTypes =
    activeTrassFilter === 'SURFACE'
      ? availableSurfaceTypes
      : activeTrassFilter === 'USAGE'
        ? availableUsageTypes
        : availableLayingTypes;

  const currentFilterNumber = getNumberSelected(availableFilterTypes, currentFilterTypes);

  const items: TabsProps['items'] = [
    {
      label: t('components.filterBox.trassesFilters.tabs.usage'),
      children: (
        <CheckBoxGroupInversed
          availableItems={availableUsageTypes}
          disabled={!showTrasses}
          items={usageTypes}
          setItems={setUsageTypes}
          transformLabel={(label) => usageTypeReadable[label as UsageType] ?? label}
        />
      ),
    },
    {
      label: t('components.filterBox.trassesFilters.tabs.layingType'),
      children: (
        <CheckBoxGroupInversed
          availableItems={availableLayingTypes}
          disabled={!showTrasses}
          items={layingTypes}
          setItems={setLayingTypes}
          transformLabel={(label) => layingTypeReadable[label as LayingType] ?? label}
        />
      ),
    },
    {
      label: t('components.filterBox.trassesFilters.tabs.surface'),
      children: (
        <CheckBoxGroupInversed
          availableItems={availableSurfaceTypes}
          disabled={!showTrasses}
          items={surfaceTypes}
          setItems={setSurfaceTypes}
          transformLabel={(label) => surfaceTypeReadable[label as SurfaceType] ?? label}
        />
      ),
    },
  ];

  if (flagTrenchProfile.enabled) {
    items.push({
      label: (
        <BadgeTrenchProfile>
          {t('components.filterBox.trassesFilters.tabs.trenchProfile')}
        </BadgeTrenchProfile>
      ),
      children: (
        <Stack gap={5} p={1}>
          <Stack gap={1}>
            <ToggleButtonGroup<string>
              setValue={(value) => setTrenchProfileFilter(value as 'depth' | 'width')}
              value={trenchProfileFilter}
              values={[
                {
                  value: 'depth',
                  label: (
                    <Stack alignItems="center">
                      <TrenchDepthOutline fontSize="24px" />
                      <Typography textTransform="none">{t('common.depth')}</Typography>
                    </Stack>
                  ),
                },
                {
                  value: 'width',
                  label: (
                    <Stack alignItems="center">
                      <TrenchWidthOutline fontSize="24px" />
                      <Typography textTransform="none">{t('common.width')}</Typography>
                    </Stack>
                  ),
                },
              ]}
            />
            <Typography variant="body2">
              {t('components.filterBox.trassesFilters.trenchProfile.helperText')}
            </Typography>
          </Stack>
          <Slider
            disableSwap
            max={200}
            min={0}
            onChange={(_, value) => setTrenchProfileRange(value as number[])}
            sx={{ width: '80%', m: '0 auto' }}
            value={trenchProfileRange}
            valueLabelDisplay="on"
          />
        </Stack>
      ),
    });
  }

  return (
    <AccordionWithCheckbox
      checked={showTrasses}
      hasPadding={false}
      onCheck={setShowTrasses}
      postTitle={
        <Stack direction="row" gap={1}>
          <ChipTrenchProfile />
          {!showTrasses ? null : (
            <Chip
              color="primary"
              label={`${!currentFilterTypes.length ? t('components.filterBox.trassesFilters.activeInfo.all') : currentFilterNumber} ${t('components.filterBox.trassesFilters.activeInfo.active')}`}
            />
          )}
        </Stack>
      }
      title={title}
    >
      <Tabs
        index={TrassFilterEnum[activeTrassFilter]}
        isFullHeight={false}
        items={items}
        onChange={(selectedIndex) =>
          setActiveTrassFilter(TrassFilterEnum[selectedIndex] as TrassFilter)
        }
        variant={flagTrenchProfile.enabled ? 'scrollable' : 'standard'}
      />
    </AccordionWithCheckbox>
  );
};
