import { useAuth0 } from '@auth0/auth0-react';
import { LoadingScreen } from '@deepup/loading-screen';
import flagsmith from 'flagsmith';
import { useFlagsmithLoading } from 'flagsmith/react';
import { type ReactNode, useCallback, useEffect, useState } from 'react';

import { getEnvironment } from '@utils/getEnvironment';
import { trackEvent } from '@utils/trackEvent';

export const FlagsmithInit = ({ children }: { children: ReactNode }) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [isIdentified, setIsIdentified] = useState(false);
  const { user } = useAuth0();
  const { flagsmithEnvId, flagSmithListeningInterval } = getEnvironment();
  const loadingState = useFlagsmithLoading();

  const trackFeatureFlags = () => {
    const feature_flags = flagsmith.getAllFlags();

    trackEvent('featureFlags', {
      feature_flags,
    });
  };

  // track features
  useEffect(() => {
    if (!isInitialized) {
      return;
    }
    trackFeatureFlags();
  }, [isInitialized]);

  const flagSmithOnChange = useCallback(() => {
    trackFeatureFlags();
  }, []);

  // initialize flagsmith
  useEffect(() => {
    flagsmith
      .init({ environmentID: flagsmithEnvId ?? '', onChange: flagSmithOnChange })
      .then(() => setIsInitialized(true));
  }, [flagSmithOnChange, flagsmithEnvId]);

  // start listening interval
  useEffect(() => {
    if (!isInitialized) {
      return;
    }
    if (!flagSmithListeningInterval) return;
    const interval = parseInt(flagSmithListeningInterval, 10);

    !isNaN(interval) && flagsmith.startListening(interval);
  }, [flagSmithListeningInterval, isInitialized]);

  // identify user
  useEffect(() => {
    if (!isInitialized) {
      return;
    }
    const userDomain = user?.email?.split('@')[1]?.toLowerCase();

    if (!userDomain) {
      setIsIdentified(true);

      return;
    }

    const isDeepUp = userDomain.startsWith('deepup') ?? false;

    flagsmith
      .identify(userDomain, { role: isDeepUp ? 'deepup' : 'customer' })
      .then(() => setIsIdentified(true));
  }, [user, isInitialized]);

  if (!isInitialized || !isIdentified || loadingState?.isLoading || loadingState?.isFetching) {
    return <LoadingScreen />;
  }

  return children;
};
