/* eslint-disable react/no-unknown-property */
import {
  BoundsOnLoad,
  DeepUpModel,
  Measurement,
  type MeasurementState,
  Viewer3D,
  Viewer3DHelp,
} from '@deepup/viewer3d';
import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import { Html } from '@react-three/drei';
import { Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { LoadingScreen } from '@components/LoadingScreen';
import { useApiHdMesh } from '@hooks/useApiHdMesh';
import { useThemeMode } from '@hooks/useThemeMode';
import { FetchError } from '@utils/FetchError';
import { trackEvent } from '@utils/trackEvent';

export type MeshViewerProps = {
  className?: string;
  scanId: string;
};

const MeshViewerStyled = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const MeshViewer = ({ className, scanId }: MeshViewerProps) => {
  const { t } = useTranslation();
  const { data: manifest, error, isFetching } = useApiHdMesh(scanId);
  const {
    isDarkMode,
    theme: {
      palette: { grey },
    },
  } = useThemeMode();

  // tracking
  useEffect(() => {
    if (!isFetching) {
      trackEvent('meshViewerOpened', { scan_id: scanId, is_error: !!error });
    }
  }, [error, isFetching, manifest, scanId]);

  const onStateChange = (state: MeasurementState) => {
    if (state === 'depth-step1') {
      trackEvent('measurementStarted', { scan_id: scanId, measurement_type: 'depth' });
    } else if (state === 'width-step1') {
      trackEvent('measurementStarted', { scan_id: scanId, measurement_type: 'width' });
    } else if (state === 'depth-ready') {
      trackEvent('measurementCompleted', { scan_id: scanId, measurement_type: 'depth' });
    } else if (state === 'width-ready') {
      trackEvent('measurementCompleted', { scan_id: scanId, measurement_type: 'width' });
    }
  };

  if (isFetching) {
    return <LoadingScreen isDarkMode={isDarkMode} />;
  }

  if (error) {
    if (!(error instanceof FetchError)) {
      console.error(error);
    }

    return (
      <Typography>
        {error instanceof FetchError
          ? t('components.meshViewer.noMeshView')
          : t('components.meshViewer.unknownError')}
      </Typography>
    );
  }

  if (!manifest) {
    return null;
  }

  return (
    <MeshViewerStyled className={className}>
      <Measurement
        onStateChange={onStateChange}
        translations={{
          width: t('components.meshViewer.measurements.width'),
          depth: t('components.meshViewer.measurements.depth'),
          step: t('components.meshViewer.measurements.step'),
          depthHelperText: t('components.meshViewer.measurements.depthHelperText'),
          widthHelperText: t('components.meshViewer.measurements.widthHelperText'),
          guideAnimationURL: (state, mode) =>
            `https://storage.googleapis.com/webassets-deepup-production/animations/${state}_guide_de_${mode}.mp4`,
        }}
      >
        {({ measurementView, measurementControls, onCanvasClick }) => (
          <>
            <Viewer3D
              canvasProps={{
                camera: { position: [0, 3, 0], rotation: [0, 0, 0], fov: 35 },
                onClick: onCanvasClick,
              }}
            >
              <color args={[grey[50]]} attach="background" />
              <Suspense
                fallback={
                  <Html fullscreen>
                    <LoadingScreen isDarkMode={isDarkMode} />
                  </Html>
                }
              >
                <>
                  <BoundsOnLoad margin={1.0}>
                    <DeepUpModel manifest={manifest} />
                  </BoundsOnLoad>
                  {measurementView}
                </>
              </Suspense>
            </Viewer3D>
            <Box
              component="div"
              sx={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                display: 'flex',
                alignItems: 'center',
                zIndex: 1200,
              }}
            >
              {measurementControls}
              <Viewer3DHelp
                translations={{
                  heading: t('components.meshViewer.help.heading'),
                  moveLabel: t('components.meshViewer.help.moveLabel'),
                  moveChip: t('components.meshViewer.help.moveChip'),
                  zoomLabel: t('components.meshViewer.help.zoomLabel'),
                  zoomChip: t('components.meshViewer.help.zoomChip'),
                  rotateLabel: t('components.meshViewer.help.rotateLabel'),
                  rotateChip: t('components.meshViewer.help.rotateChip'),
                }}
              />
            </Box>
          </>
        )}
      </Measurement>
    </MeshViewerStyled>
  );
};
