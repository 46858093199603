import { RpcRestError } from '@deepup/api-utils';
import {
  labelingProgressApiV1 as labelingProgressApi,
  labelingProgressClientApiV1 as labelingProgressClientApi,
  commonApiV1 as commonApi,
} from '@deepup/apis';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';

import type { FilterValues } from '@hooks/useFilters';
import { useGrpcRestTransport } from '@hooks/useGrpcRestTransport';
import { toTimeRange } from '@utils/timeFormatting';

export const useApiGetTotalProgress = ({
  organizationId,
  dateRange,
  projectIds,
  listOptions,
}: {
  organizationId: string | null;
  dateRange: FilterValues['dateRange'];
  projectIds?: string[];
  listOptions: {
    pageSize: number;
    page: number;
  };
}) => {
  const transport = useGrpcRestTransport();

  return useQuery<labelingProgressApi.GetTotalProgressResponse, RpcRestError>({
    queryKey: [
      'labelingProgress',
      'getTotalProgress',
      organizationId,
      dateRange,
      projectIds,
      listOptions,
    ],
    queryFn: () => {
      const client = new labelingProgressClientApi.LabelingProgressServiceClient(transport);

      return client.getTotalProgress({
        organizationId: organizationId ?? '',
        timeRange: toTimeRange(dateRange),
        projectIds: projectIds ?? [],
        options: {
          pageSize: listOptions.pageSize,
          page: BigInt(listOptions.page),
          contextId: '',
          withPaginationInfo: true,
        },
      }).response;
    },
    staleTime: Infinity,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: 0,
    enabled: !!organizationId && !!dateRange.from?.isValid && !!dateRange.to?.isValid,
  });
};

export const useApiDownloadTotalProgress = () => {
  const transport = useGrpcRestTransport();

  return useMutation<
    labelingProgressApi.DownloadResponse,
    RpcRestError,
    {
      organizationId: string | null;
      dateRange: FilterValues['dateRange'];
      projectIds?: string[];
    }
  >({
    mutationKey: ['labelingProgress', 'downloadTotalProgress'],
    mutationFn: ({ organizationId, dateRange, projectIds }) => {
      const client = new labelingProgressClientApi.LabelingProgressServiceClient(transport);

      return client.downloadTotalProgress({
        organizationId: organizationId ?? '',
        timeRange: toTimeRange(dateRange),
        projectIds: projectIds ?? [],
      }).response;
    },
  });
};

export const useApiGetTimeSeries = ({
  organizationId,
  dateRange,
  projectIds,
  interval,
  pageSize,
}: {
  organizationId: string | null;
  dateRange: FilterValues['dateRange'];
  projectIds?: string[];
  interval: commonApi.Interval;
  pageSize: number;
}) => {
  const transport = useGrpcRestTransport();

  return useInfiniteQuery<labelingProgressApi.GetTimeSeriesResponse, RpcRestError>({
    queryKey: [
      'labelingProgress',
      'getTimeSeries',
      organizationId,
      dateRange,
      projectIds,
      interval,
      pageSize,
    ],
    queryFn: ({ pageParam }) => {
      const client = new labelingProgressClientApi.LabelingProgressServiceClient(transport);

      return client.getTimeSeries({
        organizationId: organizationId ?? '',
        timeRange: toTimeRange(dateRange),
        projectIds: projectIds ?? [],
        interval,
        options: {
          pageSize: pageSize,
          page: pageParam as bigint,
          contextId: '',
          withPaginationInfo: true,
        },
      }).response;
    },
    initialPageParam: BigInt(0),
    getNextPageParam: ({ paginationInfo }) =>
      paginationInfo && paginationInfo.page < paginationInfo.totalPages - BigInt(1)
        ? paginationInfo.page + BigInt(1)
        : undefined,
    staleTime: Infinity,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: 0,
    enabled: !!organizationId && !!dateRange.from?.isValid && !!dateRange.to?.isValid,
  });
};

export const useApiDownloadTimeSeries = () => {
  const transport = useGrpcRestTransport();

  return useMutation<
    labelingProgressApi.DownloadResponse,
    RpcRestError,
    {
      organizationId: string | null;
      dateRange: FilterValues['dateRange'];
      projectIds?: string[];
      interval: commonApi.Interval;
    }
  >({
    mutationKey: ['labelingProgress', 'downloadTimeSeries'],
    mutationFn: ({ organizationId, dateRange, projectIds, interval }) => {
      const client = new labelingProgressClientApi.LabelingProgressServiceClient(transport);

      return client.downloadTimeSeries({
        organizationId: organizationId ?? '',
        timeRange: toTimeRange(dateRange),
        projectIds: projectIds ?? [],
        interval,
      }).response;
    },
  });
};

export const useApiGetDownloadStatus = ({ id }: { id?: string }) => {
  const transport = useGrpcRestTransport();

  return useQuery<labelingProgressApi.DownloadStatus, RpcRestError>({
    queryKey: ['labelingProgress', 'getDownloadStatus', id],
    queryFn: () => {
      const client = new labelingProgressClientApi.LabelingProgressServiceClient(transport);

      return client.getDownloadStatus({
        id: id ?? '',
      }).response;
    },
    staleTime: Infinity,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchInterval: (query) =>
      query.state.data?.status === labelingProgressApi.FileStatus.PENDING ? 2000 : false,
    retry: 0,
    enabled: !!id,
  });
};
